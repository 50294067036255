iframe {
    border: 0;
}

figcaption,
figure,
main {
    display: block;
    margin: 0;
}

main {
	overflow: hidden;
  padding-left: 50px;
  padding-right: 50px;

}
@media only screen and (max-width: 576px) {
  main {
  	overflow: hidden;
    padding-left: 15px;
    padding-right: 15px;

  }
}

img {
    max-width: 100%;
}

strong{
    font-weight: $font-weight-bold;
}

button:focus {
    outline: 0;
}

label{
    font-weight: $font-weight-bold;
}
